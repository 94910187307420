.contact {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-color: #000;
  flex-direction: column;
  padding: 100px 0px;
  overflow: hidden;
  z-index: 2;
  .contactItem {
    display: flex;
    align-items: center;
    @media (max-width:$on-tablet) {
      flex-direction: column-reverse;
    }
    .head {
      flex: 1 1 65%;
      max-width: 65%;
      @media (max-width:$on-tablet) {
        flex: 1 1 100%;
        max-width: 100%;
        margin-top: 56px;
      }
    }
    .image {
      @media (max-width:$on-tablet) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    .contactlinks {
      margin-top: 56px;
      display: flex;
      a {
        display: block;
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        text-align: left;
        font-family: 'SteradianExtraLight';
        &:not(:last-child) {
          margin-right: 70px;
        }
      }
    }
  }
  .title {
    display: block;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    font-family: 'SteradianRegular';
    margin-bottom: 32px;
    color: #ff0036;
  }
  h2 {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #ff0036;
    text-align: left;
    @media (max-width:$on-tablet) {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 32px;
    }
  }
  p {
    display: block;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    text-align: left;
    font-family: 'SteradianExtraLight';
  }
  .contact-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 55px;
    @media (max-width:$on-tablet) {
      flex-direction: column;
    }
    .detail {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 33.333%;
      max-width: 33.333%;
      flex: 33.333%;
      @media (max-width:$on-tablet) {
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        br {
          display: none;
        }
      }
      span {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
        
      }
    }
  }
}