.blog-title {
  h1 {
    font-size: 45px;
    line-height: 1.15;
    font-weight: 800;
    text-align: center;
    margin-top: 200px;
    @media (max-width:$on-tablet) {
      margin-top: 130px;
    }
  }
}

.blog {
  .blog-item {
    margin-bottom: 300px;
  }
  padding-top: 200px;
  @media (max-width:$on-tablet) {
    padding-top: 100px;
  }
  .modal-title {
    .date {
      font-size: 10px;
      font-weight: 800;
      color: #111;
      margin-bottom: 50px;
      display: block;
    }
    .title {
      font-size: 45px;
      line-height: 1.15;
      font-weight: 800;
      @media (max-width:$on-tablet) {
        font-size: 35px;
        line-height: 1.1;
      }
    }
    .desc {
      font-size: 15px;
      line-height: 2;
      letter-spacing: 0.05em;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  .slider {
    .modal-slider {
      .js-modal-slider-item {
        width: calc(100vw / 2);
        @media (max-width:$on-tablet) {
          width: 100%;
        }
        img {
          height: 66vh;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .navigation-item {
      width: 50px;
      height: 50px;
      background-color: #000;
      border-radius: 50%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 8px;
      }
      img {
        width: 24px;
      }
    }
    .js-modal-next {

    }
    .js-prev-navigation {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .blog-quote {
    &-item {
      line-height: 100px;
      padding: 0 0 0 50px;
      border-left: 5px solid #5f5f5f;
      margin: 0;
      box-sizing: border-box;
      font-size: 15px;
    }
  }
}