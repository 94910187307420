.header {
  position: absolute;
  top: 58px;
  width: 100%;
  z-index: 999;
  @media (max-width:$on-tablet) {
    top: 8px;
    max-height: 60px;
  }
  .container {
    @media (max-width:$on-tablet) {
      max-width: 100%;
      width: 100%;
    }
  }
  &.sticky {
    padding: 24px 0px;
    position: fixed;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid #cbccd0;
    -webkit-animation: smoothScroll .5s forwards;
    animation: smoothScroll .5s forwards;
    top: 0;
    background-color: #fff;
    z-index: 999;
    @media (max-width:$on-tablet) {
      padding: 0;
    }
    a {
      color: #111111 !important;
    }
    .mobile-collapse-button {
      .icon-bar {
        background-color: #000;
      }
    }
    .bNaw {
      @media (max-width:$on-tablet) {
        top: 60px;

      }
    }
  }
  .headerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bLogo {
    display: block;
    height: 64px;
    max-height: 64px;
    @media (max-width:$on-tablet) {
      height: 57px;
      max-height: 57px;
    }
    img {
      height: 64px;
      max-height: 64px;
      @media (max-width:$on-tablet) {
        height: 57px;
        max-height: 57px;
      }
    }
  }
  .bNaw {
    display: flex;
    @media (max-width:$on-tablet) {
      display: none;
      position: fixed;
      background-color: #fff;
      left: 0;
      width: 100%;
      flex-direction: column;
      box-shadow: bottom 0 0 5px rgba(0, 0, 0, .35);
      top: 75px;
      padding-top: 15px;
      padding-bottom:15px;
    }
    a {
      font-weight: 400;
      font-size: 12px;
      color: #fa0000;
      display: block;
      margin-right: 32px;
      font-family: 'SteradianRegular';
      
      @media (max-width:$on-tablet) {
        padding: 10px 15px;
        font-size: 11px;
        font-weight: 700;
        color: #5f5f5f;
        margin-bottom: 8px;
      }
    }
  }
  .mobile-collapse-button {
    @media (max-width:$on-tablet) {
      display: block !important;
    }
    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: #fff;
      display: block;
      margin-bottom: 4px;
    }
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes smoothScrollTop {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateY(-142px);
  }
}