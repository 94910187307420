.banner {
  height: 100vh;
  .bannerItem {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    transform: scale(1.15);
    transition: opacity .6s ease-in-out, transform 2s ease .7s;
    @media (max-width:$on-tablet) {
      background-position: right;
    }
    &.load {
      transform: scale(1);
    }
    .title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
    }
    .titleSection {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 700px;
      position: relative;
      margin-right: 60px;
      @media (max-width:$on-tablet) {
        margin-right: 0;
      }
      &:before {
        content: "";
        width: 70px;
        height: 3px;
        background: red;
        position: absolute;
        left: 0;
        top: -40px;      
      }
      .title {
        font-size: 20px;
        color: #fff;
        display: block;
        margin-bottom: 15px;
        text-align: left;
        width: 100%;
        font-family: 'SteradianLight';
      }
      .desc {
        color: #fff;
        display: block;
        font-size: 53px;
        text-align: left;
        font-family: 'SteradianLight';
        @media (max-width:$on-tablet) {
          font-size: 45px;
          line-height: 55px;
        }
      }
    }
  }
  img {
    height: 100%;
  }
  .border-left {
    position: fixed;
    width: 40px;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease;
    &.secondary-position {
      transform: translateX(-200px);
      transition: all 1.0s ease;
    }
    @media (max-width:$on-tablet) {
      display: none;
    }
  }
  .border-right {
    position: fixed;
    width: 40px;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease;
    &.secondary-position {
      transform: translateX(200px);
      transition: all 1.0s ease;
    }
    @media (max-width:$on-tablet) {
      display: none;
    }
  }
  .border-bottom {
    position: fixed;
    width: 100%;
    height: 40px;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease;
    @media (max-width:$on-tablet) {
      display: none;
    }
  }
}
