/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
@import url(../../../node_modules/animate.css/animate.min.css);
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height 0.5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

@font-face {
  font-family: 'barlow-bold';
  src: url("../scss/fonts/BarlowCondensed-Bold.eot");
  src: url("../scss/fonts/BarlowCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/BarlowCondensed-Bold.woff2") format("woff2"), url("../scss/fonts/BarlowCondensed-Bold.woff") format("woff"), url("../scss/fonts/BarlowCondensed-Bold.ttf") format("truetype"), url("../scss/fonts/BarlowCondensed-Bold.svg#BarlowCondensed-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SteradianUltraLight';
  src: url("../scss/fonts/St100100-UltLt.eot");
  /* IE9 Compat Modes */
  src: url("../scss/fonts/St100100-UltLt.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/St100100-UltLt.woff2") format("woff2"), url("../scss/fonts/St100100-UltLt.woff") format("woff"), url("../scss/fonts/St100100-UltLt.ttf") format("truetype"), url("../scss/fonts/St100100-UltLt.svg#font") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'SteradianLight';
  src: url("../scss/fonts/St100100-Lt.eot");
  /* IE9 Compat Modes */
  src: url("../scss/fonts/St100100-Lt.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/St100100-Lt.woff2") format("woff2"), url("../scss/fonts/St100100-Lt.woff") format("woff"), url("../scss/fonts/St100100-Lt.ttf") format("truetype"), url("../scss/fonts/St100100-Lt.svg#font") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'SteradianRegular';
  src: url("../scss/fonts/St100100-Rg.eot");
  /* IE9 Compat Modes */
  src: url("../scss/fonts/St100100-Rg.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/St100100-Rg.woff2") format("woff2"), url("../scss/fonts/St100100-Rg.woff") format("woff"), url("../scss/fonts/St100100-Rg.ttf") format("truetype"), url("../scss/fonts/St100100-Rg.svg#font") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'SteradianExtraLight';
  src: url("../scss/fonts/St100100-XLt.eot");
  /* IE9 Compat Modes */
  src: url("../scss/fonts/St100100-XLt.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/St100100-XLt.woff2") format("woff2"), url("../scss/fonts/St100100-XLt.woff") format("woff"), url("../scss/fonts/St100100-XLt.ttf") format("truetype"), url("../scss/fonts/St100100-XLt.svg#font") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'roboto-regular';
  src: url("../scss/Roboto-Regular.eot");
  src: url("../scss/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../scss/Roboto-Regular.woff2") format("woff2"), url("../scss/Roboto-Regular.woff") format("woff"), url("../scss/Roboto-Regular.ttf") format("truetype"), url("../scss/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'roboto-bold';
  src: url("../scss/Roboto-Bold.eot");
  src: url("../scss/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../scss/Roboto-Bold.woff2") format("woff2"), url("../scss/Roboto-Bold.woff") format("woff"), url("../scss/Roboto-Bold.ttf") format("truetype"), url("../scss/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'helvetica-light';
  src: url("../scss/fonts/HelveticaNeu-Light.eot");
  src: url("../scss/fonts/HelveticaNeu-Light.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/HelveticaNeu-Light.woff") format("woff"), url("../scss/fonts/HelveticaNeu-Light.ttf") format("truetype"), url("../scss/fonts/HelveticaNeu-Light.svg#HelveticaNeue-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'helvetica-lt';
  src: url("../scss/fonts/HelveticaNeueLTPro-Lt.eot");
  src: url("../scss/fonts/HelveticaNeueLTPro-Lt.eot?#iefix") format("embedded-opentype"), url("../scss/fonts/HelveticaNeueLTPro-Lt.woff2") format("woff2"), url("../scss/fonts/HelveticaNeueLTPro-Lt.woff") format("woff"), url("../scss/fonts/HelveticaNeueLTPro-Lt.ttf") format("truetype"), url("../scss/fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.header {
  position: absolute;
  top: 58px;
  width: 100%;
  z-index: 999; }
  @media (max-width: 991px) {
    .header {
      top: 8px;
      max-height: 60px; } }
  @media (max-width: 991px) {
    .header .container {
      max-width: 100%;
      width: 100%; } }
  .header.sticky {
    padding: 24px 0px;
    position: fixed;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid #cbccd0;
    -webkit-animation: smoothScroll .5s forwards;
    animation: smoothScroll .5s forwards;
    top: 0;
    background-color: #fff;
    z-index: 999; }
    @media (max-width: 991px) {
      .header.sticky {
        padding: 0; } }
    .header.sticky a {
      color: #111111 !important; }
    .header.sticky .mobile-collapse-button .icon-bar {
      background-color: #000; }
    @media (max-width: 991px) {
      .header.sticky .bNaw {
        top: 60px; } }
  .header .headerItem {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header .bLogo {
    display: block;
    height: 64px;
    max-height: 64px; }
    @media (max-width: 991px) {
      .header .bLogo {
        height: 57px;
        max-height: 57px; } }
    .header .bLogo img {
      height: 64px;
      max-height: 64px; }
      @media (max-width: 991px) {
        .header .bLogo img {
          height: 57px;
          max-height: 57px; } }
  .header .bNaw {
    display: flex; }
    @media (max-width: 991px) {
      .header .bNaw {
        display: none;
        position: fixed;
        background-color: #fff;
        left: 0;
        width: 100%;
        flex-direction: column;
        box-shadow: bottom 0 0 5px rgba(0, 0, 0, 0.35);
        top: 75px;
        padding-top: 15px;
        padding-bottom: 15px; } }
    .header .bNaw a {
      font-weight: 400;
      font-size: 12px;
      color: #fa0000;
      display: block;
      margin-right: 32px;
      font-family: 'SteradianRegular'; }
      @media (max-width: 991px) {
        .header .bNaw a {
          padding: 10px 15px;
          font-size: 11px;
          font-weight: 700;
          color: #5f5f5f;
          margin-bottom: 8px; } }
  @media (max-width: 991px) {
    .header .mobile-collapse-button {
      display: block !important; } }
  .header .mobile-collapse-button .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    display: block;
    margin-bottom: 4px; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px); }
  100% {
    transform: translateY(0px); } }

@keyframes smoothScrollTop {
  0% {
    transform: translateX(0px); }
  100% {
    transform: translateY(-142px); } }

.banner {
  height: 100vh; }
  .banner .bannerItem {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    transform: scale(1.15);
    transition: opacity .6s ease-in-out, transform 2s ease .7s; }
    @media (max-width: 991px) {
      .banner .bannerItem {
        background-position: right; } }
    .banner .bannerItem.load {
      transform: scale(1); }
    .banner .bannerItem .title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%; }
    .banner .bannerItem .titleSection {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 700px;
      position: relative;
      margin-right: 60px; }
      @media (max-width: 991px) {
        .banner .bannerItem .titleSection {
          margin-right: 0; } }
      .banner .bannerItem .titleSection:before {
        content: "";
        width: 70px;
        height: 3px;
        background: red;
        position: absolute;
        left: 0;
        top: -40px; }
      .banner .bannerItem .titleSection .title {
        font-size: 20px;
        color: #fff;
        display: block;
        margin-bottom: 15px;
        text-align: left;
        width: 100%;
        font-family: 'SteradianLight'; }
      .banner .bannerItem .titleSection .desc {
        color: #fff;
        display: block;
        font-size: 53px;
        text-align: left;
        font-family: 'SteradianLight'; }
        @media (max-width: 991px) {
          .banner .bannerItem .titleSection .desc {
            font-size: 45px;
            line-height: 55px; } }
  .banner img {
    height: 100%; }
  .banner .border-left {
    position: fixed;
    width: 40px;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease; }
    .banner .border-left.secondary-position {
      transform: translateX(-200px);
      transition: all 1.0s ease; }
    @media (max-width: 991px) {
      .banner .border-left {
        display: none; } }
  .banner .border-right {
    position: fixed;
    width: 40px;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease; }
    .banner .border-right.secondary-position {
      transform: translateX(200px);
      transition: all 1.0s ease; }
    @media (max-width: 991px) {
      .banner .border-right {
        display: none; } }
  .banner .border-bottom {
    position: fixed;
    width: 100%;
    height: 40px;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: all 1.0s ease; }
    @media (max-width: 991px) {
      .banner .border-bottom {
        display: none; } }

.ready {
  position: relative;
  padding: 255px 0px; }
  @media (max-width: 991px) {
    .ready {
      padding: 50px 20px; }
      .ready br {
        display: none; } }
  .ready:before {
    content: '';
    background-image: url(../../../assets/images/logo-bg.jpg);
    width: 925px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    top: -40px;
    right: 0;
    z-index: -1; }
    @media (max-width: 991px) {
      .ready:before {
        right: auto;
        background-size: auto 300px;
        top: 50px; } }
  .ready .ready-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .ready .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 620px; }
    .ready .content .title {
      font-size: 23px;
      text-align: left;
      color: #111111;
      line-height: 36px;
      font-family: 'SteradianExtraLight'; }
      @media (max-width: 991px) {
        .ready .content .title {
          margin-bottom: 32px; } }
    .ready .content .desc {
      font-size: 43px;
      text-align: left;
      line-height: 55px;
      margin-bottom: 16px;
      font-family: 'SteradianExtraLight'; }
      @media (max-width: 991px) {
        .ready .content .desc {
          font-size: 45px;
          line-height: 55px; } }
      .ready .content .desc .red {
        color: #ff0037; }
    .ready .content .button-wrapper {
      width: 100%;
      text-align: left; }
      .ready .content .button-wrapper .button {
        color: #111;
        font-size: 14px;
        line-height: 17px;
        font-family: 'SteradianExtraLight';
        color: #000;
        text-align: left;
        border-bottom: 1px solid #ff0037;
        display: inline-block;
        margin-top: 32px;
        padding-bottom: 4px; }
        @media (max-width: 991px) {
          .ready .content .button-wrapper .button {
            margin-top: 16px; } }
  .ready .wall-image-reverse {
    position: absolute;
    width: 440px;
    height: 611px;
    top: 100px;
    left: 0; }
    .ready .wall-image-reverse img {
      height: 193px !important;
      width: 145px;
      object-fit: cover;
      object-position: center;
      border-radius: 7%;
      position: absolute; }
      .ready .wall-image-reverse img.photo-1 {
        top: 194px;
        left: 316px; }
      .ready .wall-image-reverse img.photo-2 {
        top: 97px;
        left: 156px; }
      .ready .wall-image-reverse img.photo-3 {
        top: 305px;
        left: 156px; }
      .ready .wall-image-reverse img.photo-4 {
        right: 297px;
        left: auto; }
      .ready .wall-image-reverse img.photo-5 {
        top: 208px;
        right: 297px;
        left: auto; }
      .ready .wall-image-reverse img.photo-6 {
        width: auto;
        top: 416px;
        right: 297px;
        left: auto; }
    .ready .wall-image-reverse.reverse {
      right: 0;
      left: auto; }
      .ready .wall-image-reverse.reverse .photo-1 {
        top: 194px;
        right: 255px;
        left: auto; }
      .ready .wall-image-reverse.reverse .photo-2 {
        top: 97px;
        right: 95px;
        left: auto; }
      .ready .wall-image-reverse.reverse .photo-3 {
        top: 305px;
        right: 95px;
        left: auto; }
      .ready .wall-image-reverse.reverse .photo-4 {
        left: 358px;
        right: auto; }
      .ready .wall-image-reverse.reverse .photo-5 {
        top: 208px;
        left: 358px;
        right: auto; }
      .ready .wall-image-reverse.reverse .photo-6 {
        width: auto;
        top: 416px;
        left: 358px;
        right: auto; }
    @media (max-width: 991px) {
      .ready .wall-image-reverse {
        display: none; } }

.bReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  background-position: center; }
  @media (max-width: 991px) {
    .bReady {
      height: 100%; }
      .bReady br {
        display: none;
        padding-top: 50px; } }
  .bReady .content {
    max-width: 1086px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px; }
    @media (max-width: 991px) {
      .bReady .content {
        padding: 0 20px;
        margin-bottom: 0; } }
  .bReady .title {
    margin-bottom: 32px;
    font-size: 15px;
    line-height: 25px;
    color: #111111;
    display: block;
    font-family: 'SteradianRegular';
    color: #ff0036; }
  .bReady .desc {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #111111;
    color: #ff0036; }
    @media (max-width: 991px) {
      .bReady .desc {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px; } }
  .bReady .subtitle {
    font-size: 21px;
    line-height: 32px;
    color: #111111;
    display: block;
    text-align: center;
    font-family: 'SteradianExtraLight'; }
    @media (max-width: 991px) {
      .bReady .subtitle {
        padding-bottom: 32px; } }

.crisisLab {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 991px) {
    .crisisLab {
      padding: 0 20px; }
      .crisisLab br {
        display: none; } }
  .crisisLab .titleContent {
    padding-top: 100px;
    max-width: 1086px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 58px; }
    @media (max-width: 991px) {
      .crisisLab .titleContent {
        margin-bottom: 32px;
        padding-top: 100px; } }
    .crisisLab .titleContent h2 {
      font-size: 60px;
      line-height: 75px;
      text-align: center;
      color: #111111;
      margin-bottom: 27px;
      font-family: 'SteradianRegular';
      color: #ff0036; }
      @media (max-width: 991px) {
        .crisisLab .titleContent h2 {
          font-size: 45px;
          line-height: 55px; } }
    .crisisLab .titleContent .subtitle {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      font-weight: 700;
      color: #111111;
      margin-bottom: 22px; }
    .crisisLab .titleContent .desc {
      font-size: 21px;
      line-height: 31.7px;
      text-align: center;
      color: #111111;
      margin-bottom: 22px;
      font-family: 'SteradianExtraLight'; }
  .crisisLab .boxContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
    width: 100%; }
    @media (max-width: 991px) {
      .crisisLab .boxContent {
        padding: 0 20px;
        grid-template-columns: repeat(1, 1fr); } }
    .crisisLab .boxContent .box {
      padding: 20px;
      overflow: hidden;
      position: relative;
      border-radius: 30px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      height: 400px;
      align-items: center;
      justify-content: center; }
      .crisisLab .boxContent .box.w-100 img {
        width: 100%; }
      .crisisLab .boxContent .box img {
        position: absolute;
        width: 40%;
        bottom: 0;
        right: 0;
        z-index: -1;
        border-radius: 30px;
        object-fit: cover;
        top: 0; }
        @media (max-width: 991px) {
          .crisisLab .boxContent .box img {
            bottom: 0;
            top: auto; } }
      .crisisLab .boxContent .box h3 {
        text-align: center;
        font-size: 63px;
        font-family: 'SteradianRegular';
        color: #ff0036;
        margin-top: 32px; }
        @media (max-width: 991px) {
          .crisisLab .boxContent .box h3 {
            font-size: 45px; } }
      @media (max-width: 991px) {
        .crisisLab .boxContent .box {
          height: 350px; } }
      .crisisLab .boxContent .box:hover .highlights {
        opacity: 1;
        transition: 0.7s; }
      .crisisLab .boxContent .box .highlights {
        border-radius: 30px;
        opacity: 0;
        background-color: rgba(252, 252, 252, 0.94);
        border-radius: 15px;
        transition: 0.7s;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 30px; }
        @media (max-width: 991px) {
          .crisisLab .boxContent .box .highlights {
            padding: 16px 20px; } }
        .crisisLab .boxContent .box .highlights p {
          text-align: center;
          font-family: 'SteradianExtraLight';
          font-size: 21px;
          line-height: 31px; }
          @media (max-width: 991px) {
            .crisisLab .boxContent .box .highlights p {
              font-size: 16px;
              line-height: 24px; } }
        .crisisLab .boxContent .box .highlights p {
          font-size: 18px;
          line-height: 28px;
          color: #111111;
          margin-bottom: 20px; }
          @media (max-width: 991px) {
            .crisisLab .boxContent .box .highlights p {
              font-size: 16px;
              line-height: 24px; } }

.crisisLabManagement {
  padding: 100px 0px; }
  .crisisLabManagement .head {
    display: flex;
    position: relative;
    align-items: center; }
    @media (max-width: 991px) {
      .crisisLabManagement .head {
        flex-direction: column; } }
    .crisisLabManagement .head .titleWrapper {
      margin-left: 32px;
      padding-top: 32px; }
      @media (max-width: 991px) {
        .crisisLabManagement .head .titleWrapper {
          padding-top: 0; } }
    .crisisLabManagement .head .title {
      font-size: 15px;
      line-height: 25px;
      font-weight: normal !important;
      color: #111111;
      display: block;
      font-family: 'SteradianRegular';
      color: #ff0036;
      margin-bottom: 32px; }
    .crisisLabManagement .head img {
      display: block;
      position: relative;
      left: -20px;
      width: 48%;
      transform: rotate(-7deg); }
      @media (max-width: 991px) {
        .crisisLabManagement .head img {
          width: 100%; } }
    .crisisLabManagement .head h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      font-weight: normal !important;
      color: #ff0036; }
      @media (max-width: 991px) {
        .crisisLabManagement .head h2 {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 32px; } }
    .crisisLabManagement .head .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: center; }
  .crisisLabManagement .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 95px;
    margin-bottom: 32px; }
    @media (max-width: 991px) {
      .crisisLabManagement .content {
        margin-bottom: 32px;
        margin-top: 32px;
        grid-template-columns: repeat(1, 1fr);
        gap: 32px; } }
    .crisisLabManagement .content .box {
      display: flex;
      align-items: flex-start;
      justify-content: center; }
      .crisisLabManagement .content .box:not(:last-child) {
        margin-right: 20px; }
        @media (max-width: 991px) {
          .crisisLabManagement .content .box:not(:last-child) {
            margin-right: 0; } }
      .crisisLabManagement .content .box .left {
        margin-right: 15px;
        height: 100%; }
        .crisisLabManagement .content .box .left .number {
          font-size: 145px;
          display: block;
          height: 100%;
          margin-top: -27px;
          font-family: 'SteradianUltraLight';
          color: #b20026; }
          @media (max-width: 991px) {
            .crisisLabManagement .content .box .left .number {
              font-size: 96px;
              margin-top: -20px;
              width: 120px; } }
      .crisisLabManagement .content .box .right .title {
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 23px;
        display: block;
        font-family: 'SteradianExtraLight';
        color: #b20026; }
      .crisisLabManagement .content .box .right .desc {
        font-size: 14px;
        line-height: 20px;
        font-family: 'SteradianExtraLight'; }
        @media (max-width: 991px) {
          .crisisLabManagement .content .box .right .desc br {
            display: none; } }
  .crisisLabManagement .bottomContent {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      .crisisLabManagement .bottomContent {
        padding-right: 20px;
        padding-left: 20px;
        position: relative; } }
    @media (max-width: 991px) {
      .crisisLabManagement .bottomContent img {
        bottom: -40px;
        width: 33%; } }
    .crisisLabManagement .bottomContent img {
      width: 27%;
      position: absolute;
      right: 0; }
    .crisisLabManagement .bottomContent .bottomTitle {
      font-size: 21px;
      line-height: 27px;
      margin-bottom: 23px;
      display: block;
      text-align: left;
      color: #b20026;
      font-family: 'SteradianExtraLight'; }
    .crisisLabManagement .bottomContent .bottomDesc {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
      text-align: left;
      font-family: 'SteradianExtraLight'; }

.checkUp {
  position: relative; }
  @media (max-width: 991px) {
    .checkUp .container {
      width: 100%;
      max-width: 100%;
      padding: 0; } }
  .checkUp .checkUpContent {
    display: flex;
    justify-content: space-between;
    height: 590px; }
    @media (max-width: 991px) {
      .checkUp .checkUpContent {
        flex-direction: column-reverse;
        height: 100%; } }
    .checkUp .checkUpContent .image {
      position: absolute;
      right: 0;
      max-width: 50%;
      height: 100%;
      bottom: -16px; }
      .checkUp .checkUpContent .image img {
        object-fit: cover;
        height: 100%; }
      @media (max-width: 991px) {
        .checkUp .checkUpContent .image {
          position: static;
          max-width: 100%; } }
    .checkUp .checkUpContent .content {
      max-width: 50%;
      width: 50%;
      padding-right: 65px;
      margin: auto 0; }
      @media (max-width: 991px) {
        .checkUp .checkUpContent .content {
          max-width: 100%;
          width: 100%;
          margin-top: 32px;
          padding: 0 20px; } }
      .checkUp .checkUpContent .content .collapse {
        cursor: pointer;
        border-bottom: 1px solid #ccc; }
        .checkUp .checkUpContent .content .collapse.active .icon-open::before {
          content: "-" !important; }
        .checkUp .checkUpContent .content .collapse .collapseHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px; }
          .checkUp .checkUpContent .content .collapse .collapseHead .icon-open:before {
            content: "+";
            display: block;
            font-family: "Ionicons";
            right: -2px;
            top: 20px;
            font-size: 32px;
            line-height: 16px;
            width: 15px;
            height: 15px;
            text-align: center;
            color: #5f5f5f;
            font-weight: bold;
            color: #ff0036; }
          .checkUp .checkUpContent .content .collapse .collapseHead .title {
            font-size: 19px;
            font-family: 'SteradianRegular';
            color: #ff0036;
            letter-spacing: 0.05rem;
            padding-bottom: 16px;
            padding-top: 16px;
            line-height: 27px; }
        .checkUp .checkUpContent .content .collapse .collapseContent {
          display: none;
          padding: 0px  0px 15px;
          font-size: 16px;
          line-height: 24px;
          color: #5f5f5f;
          font-family: 'SteradianExtraLight'; }

.checkUpRating {
  background-color: #ffd8e1;
  padding: 116px 0px; }
  .checkUpRating .head .title {
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 52px;
    color: #111111; }
  .checkUpRating .head h2 {
    font-size: 65px;
    line-height: 75px;
    text-align: center;
    color: #111111;
    font-weight: 700;
    margin-bottom: 56px; }
    @media (max-width: 991px) {
      .checkUpRating .head h2 {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px; } }
  .checkUpRating .head .desc {
    display: block;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
    text-align: center; }
  .checkUpRating .circularContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px; }
    .checkUpRating .circularContent .firstContent {
      margin-bottom: 40px; }
    .checkUpRating .circularContent .circularContentWrap {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 14px; }
      @media (max-width: 991px) {
        .checkUpRating .circularContent .circularContentWrap {
          grid-template-columns: repeat(1, 1fr);
          gap: 80px; } }
    .checkUpRating .circularContent .circle {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      cursor: default; }
      .checkUpRating .circularContent .circle .title {
        text-align: center;
        margin-top: 24px;
        font-size: 18px; }
    .checkUpRating .circularContent .circle .box,
    .checkUpRating .circularContent .circle .box span {
      position: absolute;
      top: 50%;
      left: 50%; }
    .checkUpRating .circularContent .circle .box {
      height: 100%;
      width: 100%;
      background: #f1f1f1;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      transition: all 0.2s; }
    .checkUpRating .circularContent .circle:hover .box {
      transform: translate(-50%, -50%) scale(0.91); }
    .checkUpRating .circularContent circle .box span,
    .checkUpRating .circularContent .text {
      background: #febf01;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    .checkUpRating .circularContent .circle .box span {
      font-size: 42px;
      transform: translate(-45%, -45%);
      transition: all 0.1s; }
    .checkUpRating .circularContent .text {
      font-size: 20px;
      font-weight: 600; }

.crisisLabTitle {
  padding: 100px 0px;
  position: relative;
  z-index: 2; }
  .crisisLabTitle:hover {
    background-color: #000;
    transition: all 0.3s linear; }
    .crisisLabTitle:hover img {
      opacity: 0.3; }
    .crisisLabTitle:hover .head .title {
      color: #fff; }
    .crisisLabTitle:hover .head h2 {
      color: #fff; }
    .crisisLabTitle:hover .head .desc {
      color: #fff; }
    .crisisLabTitle:hover .head .button {
      color: #fff; }
  .crisisLabTitle img {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    z-index: -1; }
  .crisisLabTitle .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; }
    .crisisLabTitle .head .title {
      display: block;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 52px;
      color: #111111; }
    .crisisLabTitle .head h2 {
      font-size: 65px;
      line-height: 75px;
      text-align: center;
      color: #111111;
      font-weight: 700;
      margin-bottom: 56px; }
      @media (max-width: 991px) {
        .crisisLabTitle .head h2 {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 32px; } }
    .crisisLabTitle .head .desc {
      display: block;
      font-size: 18px;
      line-height: 32px;
      color: #111111;
      text-align: center; }
  .crisisLabTitle .button {
    color: #111111;
    text-align: center;
    font-weight: 700;
    margin-top: 48px; }

.contact {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-color: #000;
  flex-direction: column;
  padding: 100px 0px;
  overflow: hidden;
  z-index: 2; }
  .contact .contactItem {
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .contact .contactItem {
        flex-direction: column-reverse; } }
    .contact .contactItem .head {
      flex: 1 1 65%;
      max-width: 65%; }
      @media (max-width: 991px) {
        .contact .contactItem .head {
          flex: 1 1 100%;
          max-width: 100%;
          margin-top: 56px; } }
    @media (max-width: 991px) {
      .contact .contactItem .image {
        flex: 1 1 100%;
        max-width: 100%; } }
    .contact .contactItem .contactlinks {
      margin-top: 56px;
      display: flex; }
      .contact .contactItem .contactlinks a {
        display: block;
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        text-align: left;
        font-family: 'SteradianExtraLight'; }
        .contact .contactItem .contactlinks a:not(:last-child) {
          margin-right: 70px; }
  .contact .title {
    display: block;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    font-family: 'SteradianRegular';
    margin-bottom: 32px;
    color: #ff0036; }
  .contact h2 {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #ff0036;
    text-align: left; }
    @media (max-width: 991px) {
      .contact h2 {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px; } }
  .contact p {
    display: block;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    text-align: left;
    font-family: 'SteradianExtraLight'; }
  .contact .contact-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 55px; }
    @media (max-width: 991px) {
      .contact .contact-box {
        flex-direction: column; } }
    .contact .contact-box .detail {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 33.333%;
      max-width: 33.333%;
      flex: 33.333%; }
      @media (max-width: 991px) {
        .contact .contact-box .detail {
          flex: 1 1 100%;
          max-width: 100%;
          width: 100%;
          text-align: center;
          margin-bottom: 10px; }
          .contact .contact-box .detail br {
            display: none; } }
      .contact .contact-box .detail span {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px; }

.modal {
  padding-top: 200px;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  top: 100%;
  overflow: auto;
  transition: all .4s ease-out; }
  @media (max-width: 991px) {
    .modal {
      padding-top: 100px; } }
  .modal.active {
    top: 0; }
  .modal .modal-title .date {
    font-size: 10px;
    font-weight: 800;
    color: #111;
    margin-bottom: 50px;
    display: block; }
  .modal .modal-title .title {
    font-size: 45px;
    line-height: 1.15;
    font-weight: 800; }
    @media (max-width: 991px) {
      .modal .modal-title .title {
        font-size: 35px;
        line-height: 1.1; } }
  .modal .modal-title .desc {
    font-size: 15px;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-top: 100px;
    margin-bottom: 100px; }
  .modal .slider .modal-slider .js-modal-slider-item {
    width: calc(100vw / 2); }
    @media (max-width: 991px) {
      .modal .slider .modal-slider .js-modal-slider-item {
        width: 100%; } }
    .modal .slider .modal-slider .js-modal-slider-item img {
      height: 66vh;
      object-fit: cover;
      width: 100%; }
  .modal .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px; }
    .modal .navigation .navigation-item {
      width: 50px;
      height: 50px;
      background-color: #000;
      border-radius: 50%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center; }
      .modal .navigation .navigation-item:not(:last-child) {
        margin-right: 8px; }
      .modal .navigation .navigation-item img {
        width: 24px; }
    .modal .navigation .js-prev-navigation img {
      transform: rotate(180deg); }
  .modal .blog-quote-item {
    line-height: 100px;
    padding: 0 0 0 50px;
    border-left: 5px solid #5f5f5f;
    margin: 0;
    box-sizing: border-box;
    font-size: 15px; }
  .modal .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .modal .contact-form .contact-title {
      font-size: 55px;
      letter-spacing: -0.04em;
      font-weight: 800;
      color: #111;
      text-align: center; }
      @media (max-width: 991px) {
        .modal .contact-form .contact-title {
          font-size: 45px; } }
    .modal .contact-form form {
      width: 100%; }
      .modal .contact-form form .top {
        display: flex;
        margin-top: 100px; }
        @media (max-width: 991px) {
          .modal .contact-form form .top {
            flex-direction: column; } }
        .modal .contact-form form .top .input-wrap {
          padding: 0 15px;
          box-sizing: border-box;
          width: 50%; }
          @media (max-width: 991px) {
            .modal .contact-form form .top .input-wrap {
              width: 100%; } }
      .modal .contact-form form input {
        position: relative;
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #111;
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 5px 5px;
        background: none;
        margin: 10px 0 20px 0;
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #111;
        transition: all 0.5s linear; }
      .modal .contact-form form .input-bottom-wrap {
        padding: 0 15px; }
      .modal .contact-form form textarea {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #111;
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 5px 5px;
        background: none;
        margin: 10px 0 20px 0;
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #111;
        transition: all 0.5s linear; }
  .modal .js-contact-modal-close {
    font-size: 20px;
    font-weight: 700; }

.blog-title h1 {
  font-size: 45px;
  line-height: 1.15;
  font-weight: 800;
  text-align: center;
  margin-top: 200px; }
  @media (max-width: 991px) {
    .blog-title h1 {
      margin-top: 130px; } }

.blog {
  padding-top: 200px; }
  .blog .blog-item {
    margin-bottom: 300px; }
  @media (max-width: 991px) {
    .blog {
      padding-top: 100px; } }
  .blog .modal-title .date {
    font-size: 10px;
    font-weight: 800;
    color: #111;
    margin-bottom: 50px;
    display: block; }
  .blog .modal-title .title {
    font-size: 45px;
    line-height: 1.15;
    font-weight: 800; }
    @media (max-width: 991px) {
      .blog .modal-title .title {
        font-size: 35px;
        line-height: 1.1; } }
  .blog .modal-title .desc {
    font-size: 15px;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-top: 100px;
    margin-bottom: 100px; }
  .blog .slider .modal-slider .js-modal-slider-item {
    width: calc(100vw / 2); }
    @media (max-width: 991px) {
      .blog .slider .modal-slider .js-modal-slider-item {
        width: 100%; } }
    .blog .slider .modal-slider .js-modal-slider-item img {
      height: 66vh;
      object-fit: cover;
      width: 100%; }
  .blog .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px; }
    .blog .navigation .navigation-item {
      width: 50px;
      height: 50px;
      background-color: #000;
      border-radius: 50%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center; }
      .blog .navigation .navigation-item:not(:last-child) {
        margin-right: 8px; }
      .blog .navigation .navigation-item img {
        width: 24px; }
    .blog .navigation .js-prev-navigation img {
      transform: rotate(180deg); }
  .blog .blog-quote-item {
    line-height: 100px;
    padding: 0 0 0 50px;
    border-left: 5px solid #5f5f5f;
    margin: 0;
    box-sizing: border-box;
    font-size: 15px; }

.footer {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 80px 0px; }
  .footer .newsletter {
    width: 70%;
    margin: 0 auto; }
    .footer .newsletter .newsletterItem .newsBox {
      display: flex;
      align-items: center;
      background-color: #ff0037;
      border-radius: 33px;
      padding: 45px; }
      .footer .newsletter .newsletterItem .newsBox .title {
        flex: 1 1 45%;
        max-width: 45%;
        font-size: 26px;
        line-height: 38px;
        font-weight: 700;
        font-family: "helvetica-lt";
        color: #fff;
        text-align: left; }
      .footer .newsletter .newsletterItem .newsBox .mail {
        flex: 1 1 55%;
        max-width: 55%; }
        .footer .newsletter .newsletterItem .newsBox .mail button {
          width: 24%;
          height: 46px;
          box-shadow: 0px 14px 15px rgba(0, 0, 0, 0.09);
          border-radius: 10px;
          font-family: "helvetica-lt";
          color: #000;
          margin-left: 8px;
          font-weight: 700;
          font-size: 14px; }
        .footer .newsletter .newsletterItem .newsBox .mail form {
          display: flex;
          align-items: center; }
        .footer .newsletter .newsletterItem .newsBox .mail .input {
          width: 76%;
          position: relative;
          border: 1px solid #ffffff;
          border-radius: 10px;
          display: flex;
          align-items: center; }
          .footer .newsletter .newsletterItem .newsBox .mail .input:before {
            content: "";
            background-image: url(../../../assets/images/icon-input.png);
            position: absolute;
            left: 0;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 19px;
            height: 19px;
            margin-left: 14px;
            margin-top: 5px; }
          .footer .newsletter .newsletterItem .newsBox .mail .input input {
            width: 100%;
            height: 40px;
            display: block;
            font-size: 15px;
            line-height: 24px;
            color: #fff;
            text-align: left;
            font-family: "helvetica-light";
            background-color: rgba(253, 253, 253, 0.45);
            border: none;
            padding-left: 50px; }
            .footer .newsletter .newsletterItem .newsBox .mail .input input::placeholder {
              color: #fff; }
  .footer .footerBottom {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .footer .footerBottom {
        flex-direction: column;
        justify-content: center; } }
    @media (max-width: 991px) {
      .footer .footerBottom .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        display: none; } }
    .footer .footerBottom .menu .menuTitle {
      font-size: 14px;
      line-height: 20px;
      font-family: "SteradianRegular";
      font-weight: 700;
      color: #000;
      margin-bottom: 12px; }
    .footer .footerBottom .menu .menuItem {
      display: flex; }
      .footer .footerBottom .menu .menuItem .item {
        display: flex;
        flex-direction: column; }
        .footer .footerBottom .menu .menuItem .item a {
          font-weight: 400;
          font-size: 12px;
          color: #000;
          display: block;
          margin-right: 32px;
          font-family: "SteradianExtraLight";
          margin-bottom: 16px; }
          .footer .footerBottom .menu .menuItem .item a:hover {
            color: #ff0037; }
    @media (max-width: 991px) {
      .footer .footerBottom .social {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: -1; } }
    .footer .footerBottom .social .logo {
      position: relative;
      left: -15px; }
      @media (max-width: 991px) {
        .footer .footerBottom .social .logo {
          left: -15px; } }
      .footer .footerBottom .social .logo img {
        height: 94px; }
        @media (max-width: 991px) {
          .footer .footerBottom .social .logo img {
            height: 64px; } }
    .footer .footerBottom .social .socialTitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000;
      display: block;
      margin-right: 32px;
      font-family: "SteradianExtraLight";
      margin-bottom: 16px;
      text-align: center;
      margin-top: 16px; }
      @media (max-width: 991px) {
        .footer .footerBottom .social .socialTitle {
          text-align: center;
          margin-bottom: 32px; } }
      .footer .footerBottom .social .socialTitle span {
        color: #ff0037; }
    .footer .footerBottom .social .socialLink {
      margin-top: 32px; }
      @media (max-width: 991px) {
        .footer .footerBottom .social .socialLink {
          display: flex;
          margin-top: 8px !important;
          margin-bottom: 0; } }
      .footer .footerBottom .social .socialLink a {
        font-size: 14px;
        line-height: 22px;
        font-family: "SteradianRegular";
        color: #000; }
        .footer .footerBottom .social .socialLink a:hover {
          color: #ff0037; }
        @media (max-width: 991px) {
          .footer .footerBottom .social .socialLink a {
            display: block;
            margin-bottom: 8px;
            margin-right: 16px !important;
            font-size: 12px; } }
        .footer .footerBottom .social .socialLink a.linkedin {
          margin-right: 42px; }
          @media (max-width: 991px) {
            .footer .footerBottom .social .socialLink a.linkedin {
              margin-right: 0; } }
        .footer .footerBottom .social .socialLink a.twitter {
          margin-right: 32px; }
          @media (max-width: 991px) {
            .footer .footerBottom .social .socialLink a.twitter {
              margin-right: 0; } }
    @media (max-width: 991px) {
      .footer .footerBottom .contacts {
        margin-top: 32px; } }
    .footer .footerBottom .contacts .contactsTitle {
      font-size: 14px;
      line-height: 20px;
      font-family: "SteradianRegular";
      color: #000;
      margin-bottom: 12px;
      text-align: right; }
      @media (max-width: 991px) {
        .footer .footerBottom .contacts .contactsTitle {
          text-align: center; } }
    .footer .footerBottom .contacts .contactsInfo .mailto {
      color: #FF0037 !important; }
    .footer .footerBottom .contacts .contactsInfo .info {
      display: flex;
      align-items: center;
      margin-top: 16px;
      font-size: 14px;
      color: #000;
      font-family: "SteradianExtraLight";
      justify-content: space-between; }
      @media (max-width: 991px) {
        .footer .footerBottom .contacts .contactsInfo .info {
          justify-content: center; } }
      .footer .footerBottom .contacts .contactsInfo .info img {
        width: 24px; }
      .footer .footerBottom .contacts .contactsInfo .info a {
        color: #000;
        display: block;
        margin-right: 16px; }

.logo-animation {
  min-height: 600px;
  max-height: 600px;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .logo-animation {
      max-height: 300px;
      min-height: 300px; } }
  .logo-animation img {
    max-height: 500px;
    width: auto;
    box-sizing: border-box; }
    @media (max-width: 991px) {
      .logo-animation img {
        max-height: 300px; } }
  .logo-animation-item {
    display: flex;
    justify-content: center; }

.checkUpRatingCrisis {
  background-color: #ffd8e1;
  padding: 116px 0px;
  position: relative;
  padding-bottom: 0; }
  .checkUpRatingCrisis .overlay {
    width: 100%;
    height: 250px;
    background-color: #b20026; }
  .checkUpRatingCrisis .wrapper {
    display: flex;
    padding-bottom: 116px; }
    .checkUpRatingCrisis .wrapper .image {
      position: absolute;
      right: 0;
      max-width: 50%;
      height: 100%;
      overflow: hidden;
      top: 0;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .checkUpRatingCrisis .wrapper .image {
          height: auto;
          bottom: 0;
          top: auto; } }
      @media (max-width: 575px) {
        .checkUpRatingCrisis .wrapper .image {
          max-width: 85%; } }
      .checkUpRatingCrisis .wrapper .image img {
        width: 100%; }
  .checkUpRatingCrisis .head {
    max-width: 50%;
    width: 50%;
    padding-right: 65px;
    margin: auto 0; }
    @media (max-width: 991px) {
      .checkUpRatingCrisis .head {
        padding-right: 0;
        max-width: 100%;
        width: 100%; } }
    .checkUpRatingCrisis .head .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036; }
    .checkUpRatingCrisis .head .button-wrapper a {
      color: #111;
      font-size: 14px;
      line-height: 17px;
      font-family: 'helvetica-lt';
      color: #000;
      text-align: left;
      border-bottom: 1px solid #ff0037;
      display: inline-block;
      margin-top: 32px;
      padding-bottom: 4px; }
    .checkUpRatingCrisis .head h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: left; }
      @media (max-width: 991px) {
        .checkUpRatingCrisis .head h2 {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 32px; } }
    .checkUpRatingCrisis .head .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: left;
      font-family: 'SteradianExtraLight'; }
  .checkUpRatingCrisis .circularContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px; }
    .checkUpRatingCrisis .circularContent .firstContent {
      margin-bottom: 40px; }
    .checkUpRatingCrisis .circularContent .circularContentWrap {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 14px; }
      @media (max-width: 991px) {
        .checkUpRatingCrisis .circularContent .circularContentWrap {
          grid-template-columns: repeat(1, 1fr);
          gap: 80px; } }
    .checkUpRatingCrisis .circularContent .circle {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      cursor: default; }
      .checkUpRatingCrisis .circularContent .circle .title {
        text-align: center;
        margin-top: 24px;
        font-size: 18px; }
    .checkUpRatingCrisis .circularContent .circle .box,
    .checkUpRatingCrisis .circularContent .circle .box span {
      position: absolute;
      top: 50%;
      left: 50%; }
    .checkUpRatingCrisis .circularContent .circle .box {
      height: 100%;
      width: 100%;
      background: #f1f1f1;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      transition: all 0.2s; }
    .checkUpRatingCrisis .circularContent .circle:hover .box {
      transform: translate(-50%, -50%) scale(0.91); }
    .checkUpRatingCrisis .circularContent circle .box span,
    .checkUpRatingCrisis .circularContent .text {
      background: #febf01;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    .checkUpRatingCrisis .circularContent .circle .box span {
      font-size: 42px;
      transform: translate(-45%, -45%);
      transition: all 0.1s; }
    .checkUpRatingCrisis .circularContent .text {
      font-size: 20px;
      font-weight: 600; }

.ratingSection {
  padding: 116px 0px; }

.ratingSectionItem {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ratingSectionItem h2 {
    display: block;
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
    font-family: 'SteradianRegular';
    margin-bottom: 32px;
    color: #111111;
    color: #ff0036; }
  .ratingSectionItem .title {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #ff0036;
    text-align: center; }
    @media (max-width: 991px) {
      .ratingSectionItem .title {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px; } }
  .ratingSectionItem p {
    display: block;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
    text-align: center;
    font-family: 'SteradianExtraLight'; }

.rating {
  display: flex;
  align-items: center;
  margin-top: 56px;
  justify-content: center; }
  @media (max-width: 1199px) {
    .rating {
      flex-direction: column; } }
  .rating .left {
    display: flex; }
  .rating .right {
    margin-left: 32px;
    display: flex;
    align-items: center; }
    .rating .right .contentWrap {
      display: flex; }
    @media (max-width: 1199px) {
      .rating .right {
        margin-left: 0;
        margin-top: 32px;
        flex-direction: column; } }
  .rating .barWrapper {
    width: 90px; }
    @media (max-width: 991px) {
      .rating .barWrapper.custom {
        transform: translateX(100%); } }
    .rating .barWrapper:not(:last-child) {
      margin-right: 32px; }
      @media (max-width: 991px) {
        .rating .barWrapper:not(:last-child) {
          margin-right: 16px; } }
      @media (max-width: 575px) {
        .rating .barWrapper:not(:last-child) {
          margin-right: 12px; } }
    .rating .barWrapper.customize .bar {
      background-color: #ffbfcd;
      color: #b20026; }
    .rating .barWrapper.customize-2 .bar {
      background-color: #ff0037;
      color: #fff; }
    @media (max-width: 991px) {
      .rating .barWrapper {
        width: 60px; }
        .rating .barWrapper span {
          font-size: 11.5px !important;
          text-align: center; }
        .rating .barWrapper .bar {
          font-size: 13px !important; } }
    @media (max-width: 575px) {
      .rating .barWrapper {
        width: 40px; } }
    .rating .barWrapper span {
      font-size: 18px;
      font-family: 'SteradianExtraLight';
      text-align: center;
      display: block;
      margin-top: 16px; }
    .rating .barWrapper .barcontainer {
      background-color: #e5e5e5;
      position: relative;
      width: 100%;
      height: 320px; }
    .rating .barWrapper .bar {
      background-color: #b20026;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 80%;
      box-sizing: border-box;
      animation: grow 1.5s ease-out forwards;
      transform-origin: bottom;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'SteradianExtraLight';
      font-size: 32px;
      padding-bottom: 20px; }

@keyframes grow {
  from {
    transform: scaleY(0); } }

.singleBarWrapper {
  align-self: flex-start;
  margin-left: 32px;
  width: 24px; }
  @media (max-width: 991px) {
    .singleBarWrapper {
      margin-left: 50px; } }
  .singleBarWrapper:not(:last-child) {
    margin-right: 32px; }
  .singleBarWrapper.customize .bar {
    background-color: #ffbfcd;
    color: #b20026; }
  .singleBarWrapper span {
    font-size: 18px;
    font-family: 'SteradianExtraLight';
    text-align: center;
    display: block;
    margin-top: 16px; }
  .singleBarWrapper .barcontainer {
    background-color: #e5e5e5;
    position: relative;
    width: 100%;
    height: 175px; }
  .singleBarWrapper .bar {
    background-color: #b20026;
    bottom: 0;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    animation: grow 1.5s ease-out forwards;
    transform-origin: bottom;
    color: #000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: 'SteradianExtraLight';
    align-items: center;
    font-size: 18px; }
    .singleBarWrapper .bar.first {
      background-color: #ff0037; }
    .singleBarWrapper .bar.second {
      background-color: #ffbfcd; }
    .singleBarWrapper .bar.third {
      background-color: #b20026;
      color: #fff; }

@keyframes grow {
  from {
    transform: scaleY(0); } }
  .singleBarWrapper .items {
    display: flex;
    align-items: flex-end;
    transform: translate(100%);
    position: absolute;
    top: -4px; }
    .singleBarWrapper .items.single {
      bottom: -4px; }
    .singleBarWrapper .items div {
      font-size: 12px;
      margin-right: 8px; }
      .singleBarWrapper .items div.red {
        color: #ff0037; }
      .singleBarWrapper .items div.pink {
        color: #ffbfcd; }
      .singleBarWrapper .items div.dark {
        color: #b20026; }
    .singleBarWrapper .items .symbol {
      position: relative;
      top: 4px; }

.imageWrap {
  display: flex;
  align-items: center;
  height: 100%; }
  @media (max-width: 1199px) {
    .imageWrap {
      transform: rotate(90deg);
      margin-top: 16px;
      margin-bottom: 16px; } }
  .imageWrap img {
    width: 56px;
    display: block;
    margin-right: 32px; }

.buttonWrapper {
  display: flex;
  justify-content: center; }
  .buttonWrapper a {
    color: #111;
    font-size: 14px;
    line-height: 17px;
    font-family: 'SteradianExtraLight';
    color: #000;
    text-align: left;
    border-bottom: 1px solid #ff0037;
    display: inline-block;
    margin-top: 56px;
    padding-bottom: 4px; }

.checkUpRatingCrisis2 {
  background-color: #252525;
  padding: 116px 0px;
  position: relative; }
  .checkUpRatingCrisis2 .wrapper {
    display: flex;
    padding-bottom: 116px; }
    .checkUpRatingCrisis2 .wrapper .image {
      position: absolute;
      right: -33px;
      max-width: 50%;
      overflow: hidden;
      bottom: 0;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .checkUpRatingCrisis2 .wrapper .image {
          height: auto;
          bottom: 0;
          top: auto; } }
      @media (max-width: 575px) {
        .checkUpRatingCrisis2 .wrapper .image {
          max-width: 85%; } }
      .checkUpRatingCrisis2 .wrapper .image img {
        width: 100%; }
  .checkUpRatingCrisis2 .head {
    max-width: 50%;
    width: 50%;
    padding-right: 65px;
    margin: auto 0; }
    @media (max-width: 991px) {
      .checkUpRatingCrisis2 .head {
        padding-right: 0;
        max-width: 100%;
        width: 100%; } }
    .checkUpRatingCrisis2 .head .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036; }
    .checkUpRatingCrisis2 .head .button-wrapper a {
      color: #fff;
      font-size: 14px;
      line-height: 17px;
      font-family: 'SteradianExtraLight';
      text-align: left;
      border-bottom: 1px solid #ff0037;
      display: inline-block;
      margin-top: 32px;
      padding-bottom: 4px; }
    .checkUpRatingCrisis2 .head h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: left; }
      @media (max-width: 991px) {
        .checkUpRatingCrisis2 .head h2 {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 32px; } }
    .checkUpRatingCrisis2 .head .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      text-align: left;
      font-family: 'SteradianExtraLight'; }

.people {
  padding: 116px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .people .head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .people .head .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036; }
    .people .head h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: center; }
      @media (max-width: 991px) {
        .people .head h2 {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 32px; } }
    .people .head p {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: center;
      font-family: 'SteradianExtraLight'; }
  .people .contentBox {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px; }
    @media (max-width: 991px) {
      .people .contentBox {
        grid-template-columns: repeat(1, 1fr); } }
    .people .contentBox .title {
      display: block;
      font-size: 17px;
      line-height: 28px;
      color: #111111;
      text-align: center;
      font-family: 'SteradianExtraLight';
      font-style: italic; }
    .people .contentBox .box .image {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column; }
      .people .contentBox .box .image .icon {
        display: block; }
        .people .contentBox .box .image .icon:not(:last-child) {
          margin-right: 16px; }
        .people .contentBox .box .image .icon img {
          max-width: 50px; }
      .people .contentBox .box .image .highlights {
        border-radius: 30px;
        opacity: 0;
        background-color: rgba(252, 252, 252, 0.94);
        border-radius: 15px;
        transition: 0.7s;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 30px; }
        @media (max-width: 991px) {
          .people .contentBox .box .image .highlights {
            padding: 16px 20px; } }
    .people .contentBox .box:hover .highlights {
      opacity: 1; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  width: 100%;
  min-width: 100%;
  padding: 0 !important;
  position: relative; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 100%;
  min-height: 100%; }

img {
  width: 100%; }

* {
  outline: none; }

a:hover {
  cursor: pointer !important;
  text-decoration: none;
  color: inherit; }

html {
  scroll-behavior: smooth; }

* {
  font-family: 'helvetica-light'; }

.more-wrapper-center {
  padding: 100px 0px;
  display: flex;
  justify-content: center; }
  .more-wrapper-center .button {
    width: 120px;
    height: 120px;
    padding: 2.5px;
    border: 2px dashed #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .more-wrapper-center .button-primary {
    color: #000;
    font-size: 10px;
    text-align: center;
    line-height: 18px;
    font-weight: 700; }
  .more-wrapper-center.white .button {
    border-color: #fff; }
  .more-wrapper-center.white .button-primary {
    color: #fff; }

.o-hidden {
  position: relative;
  overflow: hidden; }

.section-all {
  margin-left: 40px;
  margin-right: 40px; }
  @media (max-width: 991px) {
    .section-all {
      margin-right: 0;
      margin-left: 0; } }
  .section-all.right-none {
    margin-right: 0; }
  .section-all.left-none {
    margin-left: 0; }
  .section-all.wPlus {
    margin-left: 160px;
    margin-right: 160px; }

.breadyContainer {
  max-width: 1384px;
  margin: 0 auto; }
  @media (max-width: 1199px) {
    .breadyContainer {
      padding-left: 20px;
      padding-right: 20px; } }

* {
  font-weight: normal !important;
  scroll-padding-top: 30px; }

.kvkk {
  z-index: 999;
  max-width: 450px;
  position: fixed;
  left: 8px;
  right: 8px;
  bottom: 16px;
  font-family: 'SteradianExtraLight';
  background-color: #252525;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px; }
  .kvkk .kvkk-item {
    padding: 20px;
    position: relative; }
    .kvkk .kvkk-item a {
      color: #fff;
      text-decoration: underline; }
      .kvkk .kvkk-item a:hover {
        color: #fff; }
    .kvkk .kvkk-item .js-close {
      position: absolute;
      right: 12px;
      top: 5px;
      cursor: pointer; }

.kvkk-wrapper {
  margin-top: 180px; }
  .kvkk-wrapper .content {
    margin-bottom: 32px; }
  .kvkk-wrapper h1 {
    font-family: 'SteradianRegular';
    font-size: 45px;
    margin-bottom: 44px; }
  .kvkk-wrapper h2 {
    font-size: 20px;
    font-family: 'SteradianRegular'; }
  .kvkk-wrapper ul {
    list-style-type: disc; }
    .kvkk-wrapper ul li {
      margin-left: 18px; }
  .kvkk-wrapper p {
    font-size: 16px;
    text-align: left;
    color: #111111;
    line-height: 24px;
    font-family: 'SteradianExtraLight';
    margin-top: 16px; }
