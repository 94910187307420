.checkUpRatingCrisis {
  background-color: #ffd8e1;
  padding: 116px 0px;
  position: relative;
  padding-bottom: 0;

  .overlay {
    width: 100%;
    height: 250px;
    background-color: #b20026;
  }
  .wrapper {
    display: flex;
    padding-bottom:116px;
    .image {
      position: absolute;
      right: 0;
      max-width: 50%;
      height: 100%;
      overflow: hidden;
      top: 0;
      display: flex;
      justify-content: flex-end;
      @media (max-width:$on-tablet) {
        height: auto;
        bottom: 0;
        top: auto;
      }
      @media (max-width:$on-small) {
        max-width: 85%;
      }
      img {
        width: 100%;
      }
    }
  }
  .head {
    max-width: 50%;
    width: 50%;
    padding-right: 65px;
    margin: auto 0;
    @media (max-width:$on-tablet) {
      padding-right: 0;
      max-width: 100%;
      width: 100%;
    }
    .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036;
    }
    .button-wrapper {
      a {
        color: #111;
        font-size: 14px;
        line-height: 17px;
        font-family: 'helvetica-lt';
        color: #000;
        text-align: left;
        border-bottom: 1px solid #ff0037;
        display: inline-block;
        margin-top: 32px;
        padding-bottom: 4px;
      }
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: left;

      @media (max-width: $on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: left;
      font-family: 'SteradianExtraLight';
    }
  }
  .circularContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .firstContent {
      margin-bottom: 40px;
    }
    .circularContentWrap {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 14px;
      @media (max-width:$on-tablet) {
        grid-template-columns: repeat(1,1fr);
        gap: 80px;
      }
    }
    .circle {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      cursor: default;
      .title {
        text-align: center;
        margin-top: 24px;
        font-size: 18px;
      }
    }
    .circle .box,
    .circle .box span {
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .circle .box {
      height: 100%;
      width: 100%;
      background: #f1f1f1;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      transition: all 0.2s;
    }
    .circle:hover .box {
      transform: translate(-50%, -50%) scale(0.91);
    }
    circle .box span,
    .text {
      background: #febf01;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .circle .box span {
      font-size: 42px;
      transform: translate(-45%, -45%);
      transition: all 0.1s;
    }

    .text {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
