.ready {
  position: relative;
  padding: 255px 0px;
  @media (max-width:$on-tablet) {
    padding: 50px 20px;
    br {
      display: none;
    }
  }
  &:before {
    content: '';
    background-image: url(../../../assets/images/logo-bg.jpg);
    width: 925px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    top: -40px;
    right: 0;
    z-index: -1;
    @media (max-width:$on-tablet) {
      right: auto;
      background-size: auto 300px;
      top: 50px;
    }

  }
  .ready-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 620px;
    .title {
     font-size: 23px;
     text-align: left;;
     color: #111111;
     line-height: 36px;
     font-family: 'SteradianExtraLight';
     @media (max-width:$on-tablet) {
      margin-bottom: 32px;
    }
    }
    .desc {
      font-size: 43px;
      text-align: left;
      line-height: 55px;
      margin-bottom: 16px;
      font-family: 'SteradianExtraLight';
      @media (max-width:$on-tablet) {
        font-size: 45px;
        line-height: 55px;
      }
      .red {
        color: #ff0037;
      }
    }
    .button-wrapper {
      width: 100%;
      text-align: left;
      .button {
        color: #111;
        font-size: 14px;
        line-height: 17px;
        font-family: 'SteradianExtraLight';
        color: #000;
        text-align: left;
        border-bottom: 1px solid #ff0037;
        display: inline-block;
        margin-top: 32px;
        padding-bottom: 4px;
        @media (max-width:$on-tablet) {
          margin-top: 16px;
        }
    }
    }
  }
  .wall-image-reverse {
    position: absolute;
    width: 440px;
    height: 611px;
    top: 100px;
    left: 0;
    img {
      height: 193px !important;
      width: 145px;
      object-fit: cover;
      object-position: center;
      border-radius: 7%;
      position: absolute;
      &.photo-1 {
        top: 194px;
        left: 316px;
      }
      &.photo-2 {
        top: 97px;
        left: 156px;
      }
      &.photo-3 {
        top: 305px;
        left: 156px;
      }
      &.photo-4 {
        right: 297px;
        left: auto;
      }
      &.photo-5 {
        top: 208px;
        right: 297px;
        left: auto;
      }
      &.photo-6 {
        width: auto;
        top: 416px;
        right: 297px;
        left: auto;
      }
    }
    &.reverse {
      right: 0;
      left: auto;
      .photo-1 {
        top: 194px;
        right: 255px;
        left: auto;
      }
      .photo-2 {
        top: 97px;
        right: 95px;
        left: auto;
      }
      .photo-3 {
        top: 305px;
        right: 95px;
        left: auto;
      }
      .photo-4 {
        left: 358px;
        right: auto;
      }
      .photo-5 {
        top: 208px;
        left: 358px;
        right: auto;
      }
      .photo-6 {
        width: auto;
        top: 416px;
        left: 358px;
        right: auto;
      }
    }
    @media (max-width:$on-tablet) {
      display: none;
    }
  }
}