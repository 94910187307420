//------------------------------------------------------------
// = IMPORTS
//------------------------------------------------------------
// Base
@import "css/owl.carousel.min";
@import "css/owl.theme.default.min";

@import "bootstrap";
@import "variables";
@import "./fonts/fonts.scss";
@import "./partials/footer.scss";
@import "./partials/header.scss";
@import "../../../node_modules/animate.css/animate.min.css";


@import "./header/index.scss";
@import "./components/banner.scss";
@import "./components/ready.scss";
@import "./components/bready.scss";
@import "./components/crisisLab.scss";
@import "./components/crisisLabManagement.scss";
@import "./components/checkUp.scss";
@import "./components/checkUpRating.scss";
@import "./components/crisisLabTitle.scss";
@import "./components/contact.scss";
@import "./components/modal.scss";
@import "./components/blog.scss";
@import "./components/footer.scss";
@import "./components/logo-animation.scss";
@import "./components/checkUpRatingCrisis.scss";
@import "./components/ratingSectionItem.scss";
@import "./components/checkUpRatingCrisis2.scss";
@import "./components/people.scss";

//------------------------------------------------------------
// = RESET
//------------------------------------------------------------
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  width: 100%;
  min-width: 100%;
  padding: 0 !important;
  position: relative;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 100%;
  min-height: 100%;
}
img {
  width: 100%;
}
* {
  outline: none;
}
a:hover {
  cursor: pointer !important;
  text-decoration: none;
  color: inherit;
}
html {
  scroll-behavior: smooth;
}


//------------------------------------------------------------
// = LAYOUT
//------------------------------------------------------------
@mixin line-clamp ($lines: 1, $line-height: 1, $is-max: false) {
	$height: $lines * $line-height;

	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis-lastline;
	overflow: hidden;

	@if $is-max == true {
		max-height: $height;
	} @else {
		height: $height;
	}
}
* {
  font-family: 'helvetica-light';
}

.more-wrapper-center {
  padding: 100px 0px;
  display: flex;
  justify-content: center;
  .button {
    width: 120px;
    height: 120px;
    padding: 2.5px;
    border: 2px dashed #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-primary {
    color: #000;
    font-size: 10px;
    text-align: center;
    line-height: 18px;
    font-weight: 700;
  }
  &.white {
    .button {
      border-color: #fff;
    }
    .button-primary {
      color: #fff;
    }
  }
}
.o-hidden {
  position: relative;
  overflow: hidden;
}
.section-all {
  margin-left: 40px;
  margin-right: 40px;
  @media (max-width:$on-tablet) {
    margin-right: 0;
    margin-left: 0;
  }
  &.right-none {
    margin-right: 0;
  }
  &.left-none {
    margin-left: 0;
  }
  &.wPlus {
    margin-left: 160px;
    margin-right: 160px;
  }
}
.breadyContainer {
  max-width: 1384px;
  margin: 0 auto;
  @media (max-width:$on-desktop) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

* {
  font-weight: normal !important;
  scroll-padding-top:30px;
}

.kvkk {
  z-index: 999;
  max-width: 450px;
  position: fixed;
  left: 8px;
  right: 8px;
  bottom: 16px;
  font-family: 'SteradianExtraLight';
  background-color: #252525;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  
  .kvkk-item {
    padding: 20px;
    position: relative;
    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
        color: #fff;
      }
    }
    .js-close {
      position: absolute;
      right: 12px;
      top: 5px;
      cursor: pointer;
    }
  }
}
.kvkk-wrapper {
  .content {
    margin-bottom: 32px;
  }
  margin-top: 180px;
  h1 {
    font-family: 'SteradianRegular';
    font-size: 45px;
    margin-bottom: 44px;
  }
  h2 {
    font-size: 20px;
    font-family: 'SteradianRegular';
  }
  ul {
    list-style-type: disc;
    li {
      margin-left: 18px;
    }
  }
  p {
    font-size: 16px;
    text-align: left;
    color: #111111;
    line-height: 24px;
    font-family: 'SteradianExtraLight';
    margin-top: 16px;
  }
}