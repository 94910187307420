.ratingSection {
  padding:116px 0px;
}
.ratingSectionItem {
 display: flex;
 flex-direction: column;
 align-items: center;
  h2 {
    display: block;
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
    font-family: 'SteradianRegular';
    margin-bottom: 32px;
    color: #111111;
    color: #ff0036;
    
  }
  .title {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #ff0036;
    text-align: center;
    @media (max-width:$on-tablet) {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 32px;
    }
  }
  p {
    display: block;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
    text-align: center;
    font-family: 'SteradianExtraLight';
  }
}
.rating {
  display: flex;
  align-items: center;
  margin-top: 56px;
  justify-content: center;
  @media (max-width:$on-desktop) {
    flex-direction: column;
  }
.left {
  display: flex;
}
.right {
  .contentWrap {
    display: flex;
  }
  margin-left: 32px;
  display: flex;
  align-items: center;
  @media (max-width:$on-desktop) {
    margin-left: 0;
    margin-top: 32px;
    flex-direction: column;
  }
}
  .barWrapper {
    &.custom {
      @media (max-width:$on-tablet) {
        transform: translateX(100%);
      }
    }
    &:not(:last-child) {
      margin-right: 32px;
      @media (max-width:$on-tablet) {
        margin-right: 16px;
      }
      @media (max-width:$on-small) {
        margin-right: 12px;
      }
    }
    &.customize {
      .bar {
        background-color: #ffbfcd;
        color: #b20026;
      }
    }
    &.customize-2 {
      .bar {
        background-color: #ff0037;
        color: #fff;
      }
    }
    width: 90px;
    @media (max-width:$on-tablet) {
      width: 60px;
      span {
        font-size: 11.5px !important;
        text-align: center;
      }
      .bar {
        font-size: 13px !important;
      }
    }
    @media (max-width:$on-small) {
      width: 40px;
    }
    span {
      font-size: 18px;
      font-family: 'SteradianExtraLight';
      text-align: center;
      display: block;
      margin-top: 16px;
    }
    .barcontainer{
      background-color: #e5e5e5;
      position: relative;
      width: 100%;
      height: 320px;
      //border darken(#98AFC7, 40%) 3px solid
    }
      
    .bar{
      background-color: #b20026;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 80%;
      //border-top: 6px solid #FFF;
      box-sizing: border-box;
      animation: grow 1.5s ease-out forwards;
      transform-origin: bottom;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'SteradianExtraLight';
      font-size: 32px;
      padding-bottom: 20px;
    }
    @keyframes grow{
      from{
        transform: scaleY(0);
      }
    }
      
  }
}
.singleBarWrapper {
  @media (max-width:$on-tablet) {
    margin-left: 50px;
  }
  align-self: flex-start;
  margin-left: 32px;
  &:not(:last-child) {
    margin-right: 32px;
  }
  &.customize {
    .bar {
      background-color: #ffbfcd;
      color: #b20026;
    }
  }
  width: 24px;
  span {
    font-size: 18px;
    font-family: 'SteradianExtraLight';
    text-align: center;
    display: block;
    margin-top: 16px;
  }
  .barcontainer{
    background-color: #e5e5e5;
    position: relative;
    width: 100%;
    height: 175px;
    //border darken(#98AFC7, 40%) 3px solid
  }
    
  .bar{
    background-color: #b20026;
    bottom: 0;
    width: 100%;
    height: 80%;
    //border-top: 6px solid #FFF;
    box-sizing: border-box;
    animation: grow 1.5s ease-out forwards;
    transform-origin: bottom;
    color: #000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: 'SteradianExtraLight';
    align-items: center;

    font-size: 18px;
    &.first {
      background-color: #ff0037;
    }
    &.second {
      background-color: #ffbfcd;
    }
    &.third {
      background-color: #b20026;
      color: #fff;
    }
  }
  @keyframes grow{
    from{
      transform: scaleY(0);
    }
  }
    .items {
      display: flex;
      align-items: flex-end;
      transform: translate(100%);
      position: absolute;
      top: -4px;
      &.single {
        bottom: -4px;
      }
      div {
        font-size: 12px;
        margin-right: 8px;
        &.red {
          color: #ff0037;
        }
        &.pink {
          color: #ffbfcd;
        }
        &.dark {
          color: #b20026;
        }
      }
      .symbol {
        position: relative;
        top: 4px;
        
      }
    }
}
.imageWrap {
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width:$on-desktop) {
    transform: rotate(90deg);
    margin-top: 16px;
    margin-bottom: 16px;
  }
  img {
    width: 56px;
    display: block;
    margin-right: 32px;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  a {
    color: #111;
    font-size: 14px;
    line-height: 17px;
    font-family: 'SteradianExtraLight';
    color: #000;
    text-align: left;
    border-bottom: 1px solid #ff0037;
    display: inline-block;
    margin-top: 56px;
    padding-bottom: 4px;
  }
}