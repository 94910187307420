  .crisisLab {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width:$on-tablet) {
      padding: 0 20px;
      br {
        display: none;
      }
    }
    .titleContent {
      padding-top: 100px;
      max-width: 1086px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 58px;
      @media (max-width:$on-tablet) {
        margin-bottom: 32px;
        padding-top: 100px;
      }
      h2 {
        font-size: 60px;
        line-height: 75px;
        text-align: center;
        color: #111111;
        margin-bottom: 27px;
        font-family: 'SteradianRegular';
        color: #ff0036;
        @media (max-width:$on-tablet) {
          font-size: 45px;
          line-height: 55px;
        }
      }
      .subtitle {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        font-weight: 700;
        color: #111111;
        margin-bottom: 22px;
      }
      .desc {
        font-size: 21px;
        line-height: 31.7px;
        text-align: center;
        color: #111111;
        margin-bottom: 22px;
        font-family: 'SteradianExtraLight';
      }
    }
    .boxContent {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 36px;
      width: 100%;
      @media (max-width:$on-tablet) {
        padding: 0 20px;
        grid-template-columns: repeat(1,1fr);
      }
      .box {
        padding: 20px;
        overflow: hidden;
        &.w-100 {
          img {
            width: 100%;
          }
        }
        position: relative;
        border-radius: 30px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        height: 400px;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          width: 40%;
          bottom: 0;
          right: 0;
          z-index: -1;
          border-radius: 30px;
          object-fit: cover;
          top: 0;
          @media (max-width:$on-tablet) {
            bottom: 0;
            top: auto;
          }
        }
        h3 {
          text-align: center;
          font-size: 63px;
          font-family: 'SteradianRegular';
          color: #ff0036; 
          margin-top: 32px;
          @media (max-width:$on-tablet) {
            font-size: 45px;
          }
        }
        @media (max-width:$on-tablet) {
          height: 350px;
        }
        &:hover {
          .highlights {
            opacity: 1;
            transition:0.7s;
          }
        }
        .highlights {
          border-radius: 30px;
          opacity: 0;
          background-color: rgba(252, 252, 252, 0.94);
          border-radius: 15px;
          transition:0.7s;
          width: calc(100% - 30px);
          height: calc(100% - 30px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 30px;
          @media (max-width:$on-tablet) {
            padding: 16px 20px;
          }
          p {
            text-align: center;
            font-family: 'SteradianExtraLight';
            font-size: 21px;
            line-height: 31px;
            @media (max-width:$on-tablet) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          p {
            font-size: 18px;
            line-height: 28px;
            color: #111111;
            margin-bottom: 20px;
            @media (max-width:$on-tablet) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }