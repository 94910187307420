.checkUpRating {
  background-color: #ffd8e1;
  padding: 116px 0px;
  .head {
    .title {
      display: block;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 52px;
      color: #111111;
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      text-align: center;
      color: #111111;
      font-weight: 700;
      margin-bottom: 56px;
      @media (max-width: $on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: center;
    }
  }
  .circularContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .firstContent {
      margin-bottom: 40px;
    }
    .circularContentWrap {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 14px;
      @media (max-width:$on-tablet) {
        grid-template-columns: repeat(1,1fr);
        gap: 80px;
      }
    }
    .circle {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      cursor: default;
      .title {
        text-align: center;
        margin-top: 24px;
        font-size: 18px;
      }
    }
    .circle .box,
    .circle .box span {
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .circle .box {
      height: 100%;
      width: 100%;
      background: #f1f1f1;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      transition: all 0.2s;
    }
    .circle:hover .box {
      transform: translate(-50%, -50%) scale(0.91);
    }
    circle .box span,
    .text {
      background: #febf01;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .circle .box span {
      font-size: 42px;
      transform: translate(-45%, -45%);
      transition: all 0.1s;
    }

    .text {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
