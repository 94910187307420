.checkUp {
  position: relative;
  .container {
    @media (max-width:$on-tablet) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
  .checkUpContent {
    display: flex;
    justify-content: space-between;
    height: 590px;
    @media (max-width:$on-tablet) {
      flex-direction: column-reverse;
      height: 100%;
    }
    .image {
      position: absolute;
      right: 0;
      max-width: 50%;
      height: 100%;
      bottom: -16px;
      img {
        object-fit: cover;
        height: 100%;
      }
      @media (max-width:$on-tablet) {
        position: static;
        max-width: 100%;
      }
    }
    .content {
      max-width: 50%;
      width: 50%;
      padding-right: 65px;
      margin: auto 0;
      @media (max-width:$on-tablet) {
        max-width: 100%;
        width: 100%;
        margin-top: 32px;
        padding: 0 20px;
      }
      .collapse {
        &.active {
          .icon-open {
            &::before {
              content: "-" !important;
            }
          }
        }
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        .collapseHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px;
          .icon-open {
            &:before {
              content: "+";
              display: block;
              font-family: "Ionicons";
              right: -2px;
              top: 20px;
              font-size: 32px;
              line-height: 16px;
              width: 15px;
              height: 15px;
              text-align: center;
              color: #5f5f5f;
              font-weight: bold;
              color: #ff0036;
            }
          }
          .title {
            font-size: 19px;
            font-family: 'SteradianRegular';
            color: #ff0036;
            letter-spacing: 0.05rem;
            padding-bottom: 16px;
            padding-top: 16px;
            line-height: 27px;
          }
        }
        .collapseContent {
          display: none;
          padding: 0px  0px 15px;
          font-size: 16px;
          line-height: 24px;
          color: #5f5f5f;
          font-family: 'SteradianExtraLight';
        }
      }
    }
  }
}
