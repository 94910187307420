.footer {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 80px 0px;
  .newsletter {
    width: 70%;
    margin: 0 auto;
    .newsletterItem {
      .newsBox {
        display: flex;
        align-items: center;
        background-color: #ff0037;
        border-radius: 33px;
        padding: 45px;
        .title {
          flex: 1 1 45%;
          max-width: 45%;
          font-size: 26px;
          line-height: 38px;
          font-weight: 700;
          font-family: "helvetica-lt";
          color: #fff;
          text-align: left;
        }
        .mail {
          flex: 1 1 55%;
          max-width: 55%;
          button {
            width: 24%;
            height: 46px;
            box-shadow: 0px 14px 15px rgba(0, 0, 0, 0.09);
            border-radius: 10px;
            font-family: "helvetica-lt";
            color: #000;
            margin-left: 8px;
            font-weight: 700;
            font-size: 14px;
          }
          form {
            display: flex;
            align-items: center;
          }
          .input {
            width: 76%;
            position: relative;
            border: 1px solid #ffffff;
            border-radius: 10px;
            display: flex;
            align-items: center;
            &:before {
              content: "";
              background-image: url(../../../assets/images/icon-input.png);
              position: absolute;
              left: 0;
              background-size: contain;
              background-repeat: no-repeat;
              display: block;
              width: 19px;
              height: 19px;
              margin-left: 14px;
              margin-top: 5px;
            }
            input {
              width: 100%;
              height: 40px;
              display: block;
              font-size: 15px;
              line-height: 24px;
              color: #fff;
              text-align: left;
              font-family: "helvetica-light";
              background-color: rgba(253, 253, 253, 0.45);
              border: none;
              padding-left: 50px;
              &::placeholder {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .footerBottom {
    display: flex;
    justify-content: space-between;
    @media (max-width:$on-tablet) {
      flex-direction: column;
      justify-content: center;
    }
    .menu {
      @media (max-width:$on-tablet) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        display: none;
      }
      .menuTitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "SteradianRegular";
        font-weight: 700;
        color: #000;
        margin-bottom: 12px;
      }
      .menuItem {
        display: flex;
        .item {
          display: flex;
          flex-direction: column;
          a {
            font-weight: 400;
            font-size: 12px;
            color: #000;
            display: block;
            margin-right: 32px;
            font-family: "SteradianExtraLight";
            margin-bottom: 16px;
            &:hover {
              color: #ff0037;
            }
          }
        }
      }
    }
    .social {
      @media (max-width:$on-tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: -1;
      }
      .logo {
        position: relative;
        left: -15px;
        @media (max-width:$on-tablet) {
          left: -15px;
         }
        img {
          height: 94px;
          @media (max-width:$on-tablet) {
           height: 64px;
          }
        }
      }
      .socialTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000;
        display: block;
        margin-right: 32px;
        font-family: "SteradianExtraLight";
        margin-bottom: 16px;
        text-align: center;
        margin-top: 16px;
        @media (max-width:$on-tablet) {
          text-align: center;
          margin-bottom: 32px;
        }
        span {
          color: #ff0037;
        }
      }
      .socialLink {
        @media (max-width:$on-tablet) {
          display: flex;
          margin-top: 8px !important;
          margin-bottom: 0;
        }
        margin-top: 32px;
        a {
          &:hover {
            color: #ff0037;
          }
          font-size: 14px;
          line-height: 22px;
          font-family: "SteradianRegular";
          color: #000;
          @media (max-width:$on-tablet) {
            display: block;
            margin-bottom: 8px;
            margin-right: 16px !important;
            font-size: 12px;
           }
          &.linkedin {
            margin-right: 42px;
            @media (max-width:$on-tablet) {
              margin-right: 0;
             }
          }
          &.twitter {
            margin-right: 32px;
            @media (max-width:$on-tablet) {
              margin-right: 0;
             }
          }
        }
      }
    }
    .contacts {
      @media (max-width:$on-tablet) {
        margin-top: 32px;
       }
      .contactsTitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "SteradianRegular";
        color: #000;
        margin-bottom: 12px;
        text-align: right;
        @media (max-width:$on-tablet) {
          text-align: center;
         }
      }
      .contactsInfo {
        .mailto {
          color: #FF0037 !important;
        }
        .info {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 14px;
          color: #000;
          font-family: "SteradianExtraLight";
          justify-content: space-between;
          @media (max-width:$on-tablet) {
            justify-content: center;
           }
          img {
            width: 24px;
          }
          a {
            color: #000;
            display: block;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
