@font-face {
  font-family: 'barlow-bold';
  src: url('../scss/fonts/BarlowCondensed-Bold.eot');
  src: url('../scss/fonts/BarlowCondensed-Bold.eot?#iefix') format('embedded-opentype'),
      url('../scss/fonts/BarlowCondensed-Bold.woff2') format('woff2'),
      url('../scss/fonts/BarlowCondensed-Bold.woff') format('woff'),
      url('../scss/fonts/BarlowCondensed-Bold.ttf') format('truetype'),
      url('../scss/fonts/BarlowCondensed-Bold.svg#BarlowCondensed-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SteradianUltraLight';
        src: url('../scss/fonts/St100100-UltLt.eot'); /* IE9 Compat Modes */
        src: url('../scss/fonts/St100100-UltLt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('../scss/fonts/St100100-UltLt.woff2') format('woff2'), /* Modern Browsers */
             url('../scss/fonts/St100100-UltLt.woff') format('woff'), /* Modern Browsers */
             url('../scss/fonts/St100100-UltLt.ttf') format('truetype'), /* Safari, Android, iOS */
             url('../scss/fonts/St100100-UltLt.svg#font') format('svg'); /* Legacy iOS */
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: 'SteradianLight';
          src: url('../scss/fonts/St100100-Lt.eot'); /* IE9 Compat Modes */
          src: url('../scss/fonts/St100100-Lt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
               url('../scss/fonts/St100100-Lt.woff2') format('woff2'), /* Modern Browsers */
               url('../scss/fonts/St100100-Lt.woff') format('woff'), /* Modern Browsers */
               url('../scss/fonts/St100100-Lt.ttf') format('truetype'), /* Safari, Android, iOS */
               url('../scss/fonts/St100100-Lt.svg#font') format('svg'); /* Legacy iOS */
          font-style: normal;
          font-weight: normal;
          text-rendering: optimizeLegibility;
    }

    @font-face {
      font-family: 'SteradianRegular';
            src: url('../scss/fonts/St100100-Rg.eot'); /* IE9 Compat Modes */
            src: url('../scss/fonts/St100100-Rg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                 url('../scss/fonts/St100100-Rg.woff2') format('woff2'), /* Modern Browsers */
                 url('../scss/fonts/St100100-Rg.woff') format('woff'), /* Modern Browsers */
                 url('../scss/fonts/St100100-Rg.ttf') format('truetype'), /* Safari, Android, iOS */
                 url('../scss/fonts/St100100-Rg.svg#font') format('svg'); /* Legacy iOS */
            font-style: normal;
            font-weight: normal;
            text-rendering: optimizeLegibility;
      }

      @font-face {
        font-family: 'SteradianExtraLight';
              src: url('../scss/fonts/St100100-XLt.eot'); /* IE9 Compat Modes */
              src: url('../scss/fonts/St100100-XLt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                   url('../scss/fonts/St100100-XLt.woff2') format('woff2'), /* Modern Browsers */
                   url('../scss/fonts/St100100-XLt.woff') format('woff'), /* Modern Browsers */
                   url('../scss/fonts/St100100-XLt.ttf') format('truetype'), /* Safari, Android, iOS */
                   url('../scss/fonts/St100100-XLt.svg#font') format('svg'); /* Legacy iOS */
              font-style: normal;
              font-weight: normal;
              text-rendering: optimizeLegibility;
        }

@font-face {
  font-family: 'roboto-regular';
  src: url('../scss/Roboto-Regular.eot');
  src: url('../scss/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../scss/Roboto-Regular.woff2') format('woff2'),
      url('../scss/Roboto-Regular.woff') format('woff'),
      url('../scss/Roboto-Regular.ttf') format('truetype'),
      url('../scss/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('../scss/Roboto-Bold.eot');
  src: url('../scss/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../scss/Roboto-Bold.woff2') format('woff2'),
      url('../scss/Roboto-Bold.woff') format('woff'),
      url('../scss/Roboto-Bold.ttf') format('truetype'),
      url('../scss/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'helvetica-light';
  src: url('../scss/fonts/HelveticaNeu-Light.eot');
  src: url('../scss/fonts/HelveticaNeu-Light.eot?#iefix') format('embedded-opentype'),
      url('../scss/fonts/HelveticaNeu-Light.woff') format('woff'),
      url('../scss/fonts/HelveticaNeu-Light.ttf') format('truetype'),
      url('../scss/fonts/HelveticaNeu-Light.svg#HelveticaNeue-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'helvetica-lt';
  src: url('../scss/fonts/HelveticaNeueLTPro-Lt.eot');
  src: url('../scss/fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
      url('../scss/fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
      url('../scss/fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
      url('../scss/fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
      url('../scss/fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




