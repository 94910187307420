.crisisLabManagement {
  padding: 100px 0px;
  .head {
    display: flex;
    position: relative;
    align-items: center;
    @media (max-width:$on-tablet) {
      flex-direction: column;
    }
    .titleWrapper {
      margin-left: 32px;
      padding-top: 32px;
      @media (max-width:$on-tablet) {
        padding-top: 0;
      }
    }
    .title {
      font-size: 15px;
      line-height: 25px;
      font-weight: normal !important;
      color: #111111;
      display: block;
      font-family: 'SteradianRegular';
      color: #ff0036;
      margin-bottom: 32px;
    }
    img {
      display: block;
      position: relative;
      left: -20px;
      width: 48%;
      transform: rotate(-7deg);
      @media (max-width:$on-tablet) {
        width: 100%;
      }
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      font-weight: normal !important;
      color: #ff0036;
      @media (max-width: $on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: center;
    }
  }
  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 95px;
    margin-bottom: 32px;
    @media (max-width: $on-tablet) {
      margin-bottom: 32px;
      margin-top: 32px;
      grid-template-columns: repeat(1, 1fr);
      gap: 32px;
    }
    .box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: $on-tablet) {
          margin-right: 0;
        }
      }

      .left {
        margin-right: 15px;
        height: 100%;
        @media (max-width: $on-tablet) {
          
        }
        .number {
          font-size: 145px;
          display: block;
          height: 100%;
          margin-top: -27px;
          font-family: 'SteradianUltraLight';
          color: #b20026;
          @media (max-width: $on-tablet) {
            font-size: 96px;
            margin-top: -20px;
            width: 120px;
          }
        }
      }
      .right {
        .title {
          font-size: 21px;
          line-height: 27px;
          margin-bottom: 23px;
          display: block;
          font-family: 'SteradianExtraLight';
          color: #b20026;
        }
        .desc {
          font-size: 14px;
          line-height: 20px;
          font-family: 'SteradianExtraLight';
          @media (max-width:$on-tablet) {
            br {
              display: none;
            }
          }
        }
      }
    }
  }
  .bottomContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:$on-tablet) {
      padding-right: 20px;
      padding-left: 20px;
      position: relative;
    }
    img {
      @media (max-width:$on-tablet) {
        bottom: -40px;
        width: 33%;
      }
    }
    img {
      width: 27%;
      position: absolute;
      right: 0;
    }
    .bottomTitle {
      font-size: 21px;
      line-height: 27px;
      margin-bottom: 23px;
      display: block;
      text-align: left;
      color: #b20026;
      font-family: 'SteradianExtraLight';

    }
    .bottomDesc {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
      text-align: left;
      font-family: 'SteradianExtraLight';
    }
  }
}
