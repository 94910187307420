.checkUpRatingCrisis2 {
  background-color: #252525;
  padding: 116px 0px;
  position: relative;

  .wrapper {
    display: flex;
    padding-bottom:116px;
    .image {
      position: absolute;
      right: -33px;
      max-width: 50%;
      overflow: hidden;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      @media (max-width:$on-tablet) {
        height: auto;
        bottom: 0;
        top: auto;
      }
      @media (max-width:$on-small) {
        max-width: 85%;
      }
      img {
        width: 100%;
      }
    }
  }
  .head {
    max-width: 50%;
    width: 50%;
    padding-right: 65px;
    margin: auto 0;
    @media (max-width:$on-tablet) {
      padding-right: 0;
      max-width: 100%;
      width: 100%;
    }
    .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036;
    }
    .button-wrapper {
      a {
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        font-family: 'SteradianExtraLight';
        text-align: left;
        border-bottom: 1px solid #ff0037;
        display: inline-block;
        margin-top: 32px;
        padding-bottom: 4px;
      }
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: left;

      @media (max-width: $on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    .desc {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      text-align: left;
      font-family: 'SteradianExtraLight';
    }
  }

}
