.people {
  padding: 116px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      display: block;
      font-size: 15px;
      line-height: 25px;
      text-align: left;
      font-family: 'SteradianRegular';
      margin-bottom: 32px;
      color: #111111;
      color: #ff0036;
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 55px;
      font-family: 'SteradianRegular';
      color: #ff0036;
      text-align: center;
      @media (max-width:$on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    p {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      text-align: center;
      font-family: 'SteradianExtraLight';
    }
  }
  .contentBox {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 32px;
    @media (max-width:$on-tablet) {
      grid-template-columns: repeat(1,1fr);
    }
    .title {
      display: block;
      font-size: 17px;
      line-height: 28px;
      color: #111111;
      text-align: center;
      font-family: 'SteradianExtraLight';
      font-style: italic;
    }
    .box {
      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        .icon {
          display: block;
          &:not(:last-child) {
            margin-right: 16px;
          }
          img {
            max-width: 50px;
          }
        }
        .highlights {
          border-radius: 30px;
          opacity: 0;
          background-color: rgba(252, 252, 252, 0.94);
          border-radius: 15px;
          transition:0.7s;
          width: calc(100% - 30px);
          height: calc(100% - 30px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 30px;
          @media (max-width:$on-tablet) {
            padding: 16px 20px;
          }
      }
      }
      &:hover {
        .highlights {
          opacity: 1;
        }
      }
    }
  }
}