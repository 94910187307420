.logo-animation {
  min-height: 600px;
  max-height: 600px;
  display: flex;
  align-items: center;
  @media (max-width:$on-tablet) {
    max-height: 300px;
    min-height: 300px;
  }
  img {
    @media (max-width:$on-tablet) {
      max-height: 300px;
    }
    max-height: 500px;
    width: auto;
    box-sizing: border-box;
  }
  &-item {
    display: flex;
    justify-content: center;
  }
}