.bReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  background-position: center;
  @media (max-width:$on-tablet) {
    height: 100%;
    br {
      display: none;
      padding-top: 50px;
    }
  }
  .content {
    @media (max-width:$on-tablet) {
      padding: 0 20px;
      margin-bottom: 0;
    }
    max-width: 1086px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }
  .title {
    margin-bottom: 32px;
    font-size: 15px;
    line-height: 25px;
    color: #111111;
    display: block;
    font-family: 'SteradianRegular';
    color: #ff0036;
  }
  .desc {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 55px;
    font-family: 'SteradianRegular';
    color: #111111;
    color: #ff0036;
    @media (max-width:$on-tablet) {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 32px;
    }
  }
  .subtitle {
    font-size: 21px;
    line-height: 32px;
    color: #111111;
    display: block;
    text-align: center;
    font-family: 'SteradianExtraLight';
    @media (max-width:$on-tablet) {
      padding-bottom: 32px;
    }
  }
}
