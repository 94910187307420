.modal {
  @media (max-width:$on-tablet) {
    padding-top: 100px;
  }
  padding-top: 200px;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  top: 100%;
  overflow: auto;
  transition: all .4s ease-out;
  &.active {
    top: 0;
  }
  .modal-title {
    .date {
      font-size: 10px;
      font-weight: 800;
      color: #111;
      margin-bottom: 50px;
      display: block;
    }
    .title {
      font-size: 45px;
      line-height: 1.15;
      font-weight: 800;
      @media (max-width:$on-tablet) {
        font-size: 35px;
        line-height: 1.1;
      }
    }
    .desc {
      font-size: 15px;
      line-height: 2;
      letter-spacing: 0.05em;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  .slider {
    .modal-slider {
      .js-modal-slider-item {
        width: calc(100vw / 2);
        @media (max-width:$on-tablet) {
          width: 100%;
        }
        img {
          height: 66vh;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .navigation-item {
      width: 50px;
      height: 50px;
      background-color: #000;
      border-radius: 50%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 8px;
      }
      img {
        width: 24px;
      }
    }
    .js-modal-next {

    }
    .js-prev-navigation {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .blog-quote {
    &-item {
      line-height: 100px;
      padding: 0 0 0 50px;
      border-left: 5px solid #5f5f5f;
      margin: 0;
      box-sizing: border-box;
      font-size: 15px;
    }
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-title {
      font-size: 55px;
      letter-spacing: -0.04em;
      font-weight: 800;
      color: #111;
      text-align: center;
      @media (max-width:$on-tablet) {
        font-size: 45px;
      }
    }
    form {
     width: 100%;
      .top {
        display: flex;
        margin-top: 100px;
        @media (max-width:$on-tablet) {
          flex-direction: column;
        }

        .input-wrap {
          padding: 0 15px;
          box-sizing: border-box;
          width: 50%;
          @media (max-width:$on-tablet) {
            width: 100%;
          }
        }
      }
      input {
        position: relative;
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #111;
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 5px 5px;
        background: none;
        margin: 10px 0 20px 0;
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #111;
        transition: all 0.5s linear;
      }
      .input-bottom-wrap {
        padding: 0 15px;
      }
      textarea {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #111;
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 5px 5px;
        background: none;
        margin: 10px 0 20px 0;
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #111;
        transition: all 0.5s linear;
      }
    }
  }
  .js-contact-modal-close {
    font-size: 20px;
    font-weight: 700;
  }
}