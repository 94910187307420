.crisisLabTitle {
  padding: 100px 0px;
  position: relative;
  z-index: 2;
  &:hover {
    background-color: #000;
    transition: all 0.3s linear;
    
    img {
      opacity: 0.3;
    }
    .head {
      .title {
        color: #fff;
      }
      h2 {
        color: #fff;
      }
      .desc {
        color: #fff;
      }
      .button {
        color: #fff;
      }
    }
  }
  img {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    z-index: -1;
  }
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    .title {
      display: block;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 52px;
      color: #111111;
    }
    h2 {
      font-size: 65px;
      line-height: 75px;
      text-align: center;
      color: #111111;
      font-weight: 700;
      margin-bottom: 56px;
      @media (max-width: $on-tablet) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }
    .desc {
      display: block;
      font-size: 18px;
      line-height: 32px;
      color: #111111;
      text-align: center;
    }
  }
  .button {
    color: #111111;
    text-align: center;
    font-weight: 700;
    margin-top: 48px;
  }
}